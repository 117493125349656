import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addSpinner,
  removeSpinner,
  getAllInvoices,
  timeoutShowTask,
} from "../../../data/actions";
import request from "../../../helpers/request";
import { Button, Modal } from "../../../components";
import styles from "./showInvoice.module.scss";

const ShowInvoice = ({ isModalOpen, setIsModalOpen }) => {
  const invoicesObj = useSelector((store) => store.invoicesObj);
  const clients = useSelector((store) => store.clients);
  const exchange = useSelector((store) => store.exchange);
  const isEdit = useSelector((store) => store.isEdit[0].isEdit);
  const editData = useSelector((store) => store.isEdit[0].data[0]);

  const history = useHistory();

  const dispatch = useDispatch();

  const { companyName, companyAdress, vatNo } = !clients[0] ? "" : clients[0];
  const {
    dateOfIssue,
    dateOfSales,
    dateOfPayment,
    // kindOfPayment,
    description,
    additionalDescription,
    additionalInfo,
    orderId,
    netPrice,
    currency,
    quantity,
    // vat,
    invoiceNo,
  } = !invoicesObj[0] ? "" : invoicesObj[0];

  // const { effectiveDate, mid, no } = !exchange.length ? "" : exchange[0];

  const clientData = !clients.length ? (
    ""
  ) : (
    <div>
      <p>Nabywca:</p>
      <h4>{companyName}</h4>
      <p>{companyAdress}</p>
      <p>{vatNo}</p>
    </div>
  );

  const netValue = Number(netPrice) * Number(quantity);

  // const vatValue = (netValue * Number(vat)) / 100;

  // const grossValue = netValue + vatValue;

  // const exchangeRate = netValue * mid;

  // const discriptionTaxForCurr =
  //   currency === "Pln" ? (
  //     ""
  //   ) : (
  //     <p>
  //       Wartość netto w pln <span>{exchangeRate.toFixed(2)}</span>, wartosć
  //       podatku VAT w pln <span>{(vatValue * mid).toFixed(2)}</span> wg kursu
  //       Euro z dnia{" "}
  //       <span>
  //         {effectiveDate}, <span>{mid}pln</span>{" "}
  //       </span>
  //       , tabela: <span>{no}</span>
  //     </p>
  //   );

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveInvoice = async () => {
    dispatch(addSpinner());

    if (!isEdit) {
      const invoiceObj = {
        invoiceNo: invoiceNo,
        client: clients[0],
        invoice: invoicesObj[0],
        exchange: !exchange[0] ? { no: "0" } : exchange[0],
      };

      const { data, status } = await request.post("/invoice", invoiceObj);
      if (status === 201) {
        dispatch(removeSpinner());
        dispatch(getAllInvoices([data.data]));
        dispatch(timeoutShowTask("faktura dodana"));
        history.push("/invoices");
      } else {
        dispatch(removeSpinner());
        console.log(data.message);
      }
    } else {
      const invoiceObj = {
        id: editData._id,
        invoiceNo: editData.invoiceNo,
        client: clients[0],
        invoice: invoicesObj[0],
        exchange: !exchange[0] ? { no: "0" } : exchange[0],
      };
      const { data, status } = await request.put("/invoice", invoiceObj);
      if (status === 202) {
        dispatch(removeSpinner());

        dispatch(getAllInvoices([data.data]));
        dispatch(timeoutShowTask("dane faktury zmienione"));
        history.push("/invoices");
      } else {
        dispatch(removeSpinner());
        console.log(data.message);
      }
    }
  };
  return (
    <Modal isModalOpen={isModalOpen}>
      <div className={styles.wrapper}>
        <div className={styles.dates}>
          <div>
            <p>Miasto:</p>
            <p>Szczawno Zdrój</p>
          </div>
          <div>
            <p>Data wystawienia:</p>
            <p>{dateOfIssue}</p>
          </div>
          <div>
            <p>Data sprzedaży:</p>
            <p>{dateOfSales}</p>
          </div>
          <div>
            <p>Termin płatności:</p>
            <p>30 dni</p>
          </div>
        </div>
        <div className={styles.invoiceNo}>
          <p>FAKTURA VAT Nr {invoiceNo}</p>
        </div>
        <div className={styles.buyer}>{clientData}</div>
        <div className={styles.table}>
          <table>
            <tr>
              <th>Nazwa usługi</th>
              <th>Cena netto</th>
              <th>Wartość netto</th>
            </tr>
            <tr>
              <td>
                <div className={styles.describe}>
                  <p>{description}</p>
                  <p>{additionalDescription}</p>
                  <p>{orderId}</p>
                  <p>{additionalInfo}</p>
                </div>
              </td>
              <td>
                <div>
                  <p>{Number(netPrice).toFixed(2)}</p>
                  <p>{currency}</p>
                </div>
              </td>
              <td>
                <div>
                  <p>{netValue.toFixed(2)}</p>
                  <p>{currency}</p>
                </div>
              </td>
            </tr>
            <tr className={styles.trTotal}>
              <td>Suma:</td>
              <td></td>
              <td>
                <div>
                  <p>{netValue.toFixed(2)}</p>
                  <p>{currency}</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className={styles.discribes}>
          <p>
            According to art. No 28 b paragraph 3 of Act from 11 March 2004
            about Value Added Tax. The purchaser is obligated to pay VAT from
            this transaction. Reverse charge.
          </p>

          <p>
            Art.. 28b ust.1 ustawy z dnia 11 marca 2004 o podatku od towarów i
            usług. Odwrotne obciążenie.
          </p>
        </div>

        <div className={styles.buttons}>
          <Button name="wstecz" onClick={handleCloseModal} />
          <Button name="zapisz" onClick={handleSaveInvoice} />
        </div>
      </div>
    </Modal>
  );
};

export default ShowInvoice;
