import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, BackButton } from "../../../components";
import { OWNER_DATA } from "../../../helpers/ownerData";
import styles from "./printInvoice.module.scss";

const PrintInvoice = () => {
  const invoicesObj = useSelector((store) => store.invoicesObj[0]);

  const { invoiceNo, client, invoice, exchange } = invoicesObj;
  const componentRef = useRef();

  const [plLanguage, setPlLanguage] = useState(false);

  const handleChangeLanguage = () => {
    setPlLanguage(!plLanguage);
  };

  const [orgin, setOrgin] = useState("Original");

  const { companyName, companyAdress, vatNo } = client;
  const {
    additionalDescription,
    additionalInfo,
    currency,
    dateOfIssue,
    // dateOfPayment,
    dateOfSales,
    orderId,
    description,
    netPrice,
    quantity,
    // vat,
  } = invoice;

  const netValue = Number(netPrice) * Number(quantity);

  const changeKindOfInvoice = (e) => {
    setOrgin(e.target.value);
  };

  const handleOnPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "wydruk z programu Faktury onLine www.developerweb.pl",
  });

  return (
    <div className={styles.wrapper} ref={componentRef}>
      <div className={styles.dates}>
        <div>
          <p>{!plLanguage ? "City:" : "Miasto:"}</p>
          <p>Szczawno Zdrój</p>
        </div>
        <div>
          <p>{!plLanguage ? "Date of issue:" : "Data wystawienia:"}</p>
          <p>{dateOfIssue}</p>
        </div>
        <div>
          <p>{!plLanguage ? "Date of sales:" : "Data sprzedaży:"}</p>
          <p>{dateOfSales}</p>
        </div>
        <div>
          <p>{!plLanguage ? "Date of payment:" : "Termin płatności:"}</p>
          <p>{!plLanguage ? "30 days" : "30 dni"}</p>
        </div>
      </div>
      <div className={styles.invoiceNo}>
        <p>
          {!plLanguage ? "VAT INVOICE No:" : "FAKTURA VAT Nr:"} {invoiceNo}
        </p>
        <span className={styles.invoiceKind}>{orgin}</span>
        <form className={styles.invoiceSet}>
          <select name="kindOfInvoice" onChange={changeKindOfInvoice}>
            <option value="Original">oryginał</option>
            <option value="Copy">kopia</option>
            <option value="Duplicate">duplikat</option>
          </select>
        </form>
      </div>
      <div className={styles.dealer}>
        <h5>{!plLanguage ? "Seller:" : "Sprzedawca:"}</h5>
        <div className={styles.dealerData}>
          <h4>{OWNER_DATA.companyName}</h4>
          <p>{OWNER_DATA.companyAdress}</p>
          <p>
            {!plLanguage ? "VatNo:" : "Nip:"} {OWNER_DATA.vatNo}
          </p>
          <p>
            {!plLanguage ? "Bank Name:" : "Nazwa Banku:"} {OWNER_DATA.bankName}
          </p>
          <p>
            {!plLanguage ? "Bank Account:" : "Numer konta:"}{" "}
            {OWNER_DATA.accountNo}
          </p>
          <p>
            {!plLanguage ? "Bank Swift Code:" : "SWIFT:"} {OWNER_DATA.swift}
          </p>
        </div>
        <div className={styles.dealerInfo}>
          <h5>{!plLanguage ? "contact" : "kontakt"}</h5>
          <p>eMail: {OWNER_DATA.mail}</p>
          <p>
            {!plLanguage ? "phone:" : "telefon:"} {OWNER_DATA.phone}
          </p>
          <a target="blank" href="http://www.omega-dulowski.ovh/">
            www.omega-dulowski.ovh
          </a>
        </div>
      </div>
      <div className={styles.buyer}>
        <h5>{!plLanguage ? "Buyer:" : "Nabywca:"}</h5>
        <div>
          <h4>{companyName}</h4>
          <p>{companyAdress}</p>
          <p>{vatNo}</p>
        </div>
      </div>
      <div className={styles.table}>
        <table>
          <tr>
            <th>{!plLanguage ? "Name of service:" : "Nazwa usługi:"}</th>
            <th>{!plLanguage ? "Net price:" : "Cena netto:"}</th>
            <th>{!plLanguage ? "Net value:" : "Wartość netto:"}</th>
          </tr>
          <tr>
            <td>
              <div className={styles.describe}>
                <p>{description}</p>
                <p>{additionalDescription}</p>
                <p>{orderId}</p>
                <p>{additionalInfo}</p>
              </div>
            </td>
            <td>
              <div>
                <p>{Number(netPrice).toFixed(2)}</p>
                <p>{currency}</p>
              </div>
            </td>
            <td>
              <div>
                <p>{netValue.toFixed(2)}</p>
                <p>{currency}</p>
              </div>
            </td>
          </tr>
          <tr className={styles.trTotal}>
            <td>{!plLanguage ? "Total:" : "Suma:"}</td>
            <td></td>
            <td>
              <div>
                <p>{netValue.toFixed(2)}</p>
                <p>{currency}</p>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div className={styles.discribes}>
        <p>
          According to art. No 28 b paragraph 3 of Act from 11 March 2004 about
          Value Added Tax. The purchaser is obligated to pay VAT from this
          transaction. Reverse charge.
        </p>

        <p>
          Art.. 28b ust.1 ustawy z dnia 11 marca 2004 o podatku od towarów i
          usług. Odwrotne obciążenie.
        </p>
      </div>
      <div className={styles.buttons}>
        <BackButton />
        <Button
          name={!plLanguage ? "język FV Polski" : "język FV Angielski"}
          onClick={handleChangeLanguage}
        />
        <Button type="button" name="drukuj" onClick={handleOnPrint} />
      </div>
    </div>
  );
};

export default PrintInvoice;
