export const OWNER_DATA = {
  companyName: "Przedsiębiorstwo Handlowe Omega Wiesław Dulowski",
  companyAdress: "58310 Szczawno Zdrój ul. Prusa 22",
  vatNo: "PL8861111165",
  bankName: "Satander Bank",
  swift: "WBKPPLPP",
  accountNo: "PL 55 1090 2590 0000 0001 4402 5092",
  mail: "biuro@omega-dulowski.pl",
  phone: "+48 609556743",
  www: "www.omega-dulowski.ovh",
};
