import axios from "axios";

export const request = axios.create({
  baseURL: "https://omega-invoive.herokuapp.com/",
  // baseURL: "http://localhost:8000",

  validateStatus: false,
});

export default request;
